import { BiampLogo } from '@evoko/components';
import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export function BiampBlock({ children }: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      p={5}
      borderRadius={{ xs: 0, sm: 1 }}
      minWidth={360}
      width={{ xs: '100%', sm: '33%' }}
      bgcolor="background.paper"
    >
      <BiampLogo width={100} />
      {children}
    </Box>
  );
}
