import { useAuth } from '@evoko/api';
import {
  ErrorPage,
  ErrorPageActions,
  ErrorPageDescription,
  ErrorPageDetails,
  ErrorPageTitle,
} from '@evoko/components';
import { Button, LinearProgress } from '@mui/material';
import { Navigate, Link as RouterLink } from 'react-router-dom';

import locale from '../../locale';

const LL = locale.pages.signOutCallback;

export function SignOutCallbackPage() {
  const { loading, error } = useAuth();

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <ErrorPage title={LL.error.title}>
        <ErrorPageTitle>{LL.error.title}</ErrorPageTitle>
        <ErrorPageDescription>{LL.error.description}</ErrorPageDescription>
        <ErrorPageActions>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/"
          >
            {LL.error.actions.goToLanding}
          </Button>
        </ErrorPageActions>
        <ErrorPageDetails message={error.message} sentryId={error.sentryId} />
      </ErrorPage>
    );
  }

  return <Navigate to="/" replace />;
}

export default SignOutCallbackPage;
