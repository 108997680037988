import { useEffect, useRef, useState } from 'react';

type Options = {
  delay?: number;
  minDuration?: number;
};

const defaultOptions: Options = {
  delay: 150,
  minDuration: 500,
};

type Status = 'idle' | 'delaying' | 'loading' | 'ending';

export function useLoadingDelay(
  loading: boolean,
  options: Options = defaultOptions,
) {
  const [status, setStatus] = useState<Status>('idle');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function clearTimeoutRef() {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    if (loading && status === 'idle') {
      clearTimeoutRef();

      timeoutRef.current = setTimeout(() => {
        if (!loading) {
          setStatus('idle');
          return;
        }

        timeoutRef.current = setTimeout(() => {
          setStatus('ending');
        }, options.minDuration);

        setStatus('loading');
      }, options.delay);

      setStatus('delaying');
    }

    if (!loading && status !== 'loading') {
      clearTimeoutRef();
      setStatus('idle');
    }
  }, [loading, options, status]);

  useEffect(() => {
    return () => {
      clearTimeoutRef();
    };
  }, []);

  return status === 'loading' || status === 'ending';
}

export default useLoadingDelay;
