import { ApolloProvider } from '@apollo/client';
import {
  AuthProvider,
  RequireAuth,
  createUserClient,
  getSignInCallbackUrl,
  getSignOutCallbackUrl,
} from '@evoko/api';
import {
  SignInRedirectErrorPage,
  SnackbarProvider,
  SystemMessageBanners,
} from '@evoko/components';
import { createTheme } from '@evoko/styles';
import {
  Box,
  CssBaseline,
  LinearProgress,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import ActivatePage from './pages/ActivatePage';
import EulaPage from './pages/EulaPage';
import InvitePage from './pages/InvitePage';
import LandingPage from './pages/LandingPage';
import ProfilePage from './pages/ProfilePage';
import ResourcePage from './pages/ResourcePage';
import SetupPage from './pages/SetupPage';
import SignInCallbackPage from './pages/SignInCallbackPage';
import SignOutCallbackPage from './pages/SignOutCallbackPage';

function Layout() {
  return (
    <Box
      height="100vh"
      display="grid"
      gridTemplateRows="auto 1fr"
      gridTemplateAreas={`
        "banner"
        "page"
      `}
    >
      <Stack gridArea="banner">
        <SystemMessageBanners />
      </Stack>
      <Box gridArea="page" overflow="auto">
        <Outlet />
      </Box>
    </Box>
  );
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <LandingPage /> },
        { path: 'invite', element: <InvitePage /> },
        { path: 'resource/*', element: <ResourcePage /> },
        { path: 'setup', element: <SetupPage /> },
        {
          path: getSignOutCallbackUrl().pathname,
          element: <SignOutCallbackPage />,
        },
        {
          path: getSignInCallbackUrl().pathname,
          element: <SignInCallbackPage />,
        },
        {
          element: (
            <RequireAuth
              fallback={<LinearProgress />}
              errorElement={({ error }) => (
                <SignInRedirectErrorPage error={error} />
              )}
            >
              <Outlet />
            </RequireAuth>
          ),
          children: [
            { path: 'profile/*', element: <ProfilePage /> },
            { path: 'activate', element: <ActivatePage /> },
          ],
        },
        { path: 'eula', element: <EulaPage /> },
        { path: '*', element: <Navigate to="/" replace /> },
      ],
    },
  ],
  { basename: import.meta.env.BASE_URL },
);

const theme = createTheme('dark');
const client = createUserClient();

export default function App() {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <ApolloProvider client={client}>
              <AuthProvider>
                <RouterProvider router={router} />
              </AuthProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}
