/** Adds the provided `character` as suffix to `string` if not already present. */
function addSuffix(string: string, character: string) {
  return string[string.length - 1] !== character ? string + character : string;
}

/** Removes the provided `character` as prefix from `string` if present. */
function removePrefix(string: string, character: string) {
  return string.charAt(0) === character ? string.substring(1) : string;
}

export function getPublicUrl(path = '') {
  return new URL(path, window.location.origin);
}

/** Returns a URL instance for the admin app with an optinonal appended `path`. */
export function getAdminUrl(path = '') {
  const base = getPublicUrl('/admin/');
  return new URL(removePrefix(path, '/'), base);
}

/** Returns a URL instance for the overview app with an optinonal appended `path`. */
export function getOverviewUrl(path = '') {
  const base = getPublicUrl('/overview/');
  return new URL(removePrefix(path, '/'), base);
}

/** Returns a URL instance for the super admin app with an optinonal appended `path`. */
export function getSuperAdminUrl(path = '') {
  const base = getPublicUrl('/super-admin/');
  return new URL(removePrefix(path, '/'), base);
}

/** Returns a URL instance of `VITE_BACKEND_BASE_URL` with an optinonal appended `path`. */
export function getBackendUrl(path = '') {
  const base = import.meta.env.VITE_BACKEND_BASE_URL;
  return new URL(removePrefix(path, '/'), addSuffix(base, '/'));
}

/** Returns a URL instance of `VITE_STORAGE_BASE_URL` with an optinonal appended `path`. */
export function getStorageUrl(path = '') {
  const base = import.meta.env.VITE_STORAGE_BASE_URL;
  return new URL(removePrefix(path, '/'), addSuffix(base, '/'));
}

export function getSignInCallbackUrl() {
  return getPublicUrl('/auth/signin');
}

export function getSignOutCallbackUrl() {
  return getPublicUrl('/auth/signout');
}

export function isProduction() {
  return window.location.hostname === 'evoko.app';
}
