import {
  ErrorOutline,
  InfoOutlined,
  ReportProblemOutlined,
  TaskAltOutlined,
} from '@mui/icons-material';
import { Box, BoxProps, Collapse, type AlertColor } from '@mui/material';

const iconArea = 'banner-icon';
const contentArea = 'banner-content';
const actionsArea = 'banner-actions';

type BannerProps = {
  show: boolean;
  children: React.ReactNode;
  severity: AlertColor;
};

export function Banner({ show, children, severity }: BannerProps) {
  return (
    <Collapse in={show} unmountOnExit component="aside">
      <Box
        color={(theme) => theme.palette[severity].contrastText}
        bgcolor={(theme) => theme.palette[severity].main}
        alignItems="center"
        gap={1}
        px={{ xs: 2, sm: 3 }}
        py={0.6}
        minHeight={48}
        display="grid"
        gridTemplateColumns="auto 1fr auto"
        gridTemplateRows="auto"
        gridTemplateAreas={`"${iconArea} ${contentArea} ${actionsArea}"`}
      >
        {children}
      </Box>
    </Collapse>
  );
}

// NOTE: we're using the same icon mapping as the `Alert` component.
const iconMapping: Record<BannerProps['severity'], React.ReactNode> = {
  error: <ErrorOutline />,
  warning: <ReportProblemOutlined />,
  success: <TaskAltOutlined />,
  info: <InfoOutlined />,
};

type BannerIconProps = {
  severity?: AlertColor;
} & BoxProps;

export function BannerIcon({ severity, children, ...props }: BannerIconProps) {
  return (
    <Box gridArea={iconArea} {...props}>
      {severity ? iconMapping[severity] : children}
    </Box>
  );
}

export function BannerContent({ children, ...props }: BoxProps) {
  return (
    <Box gridArea={contentArea} textAlign="center" {...props}>
      {children}
    </Box>
  );
}

export function BannerActions({ children, ...props }: BoxProps) {
  return (
    <Box gridArea={actionsArea} display="flex" gap={1} {...props}>
      {children}
    </Box>
  );
}
