import { getPublicUrl } from '@evoko/api';
import { BiampLogo, Page } from '@evoko/components';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import locale from '../../locale';
import { FaqIcon } from './assets/icons/FaqIcon';
import { LifeSaverIcon } from './assets/icons/LifeSaverIcon';
import { StartIcon } from './assets/icons/StartIcon';
import backgroundImage from './assets/naso-setup-background.jpg';

const LL = locale.pages.setup;

export default function SetupPage() {
  const now = new Date();

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      p={{ xs: 0, sm: 1 }}
      sx={{
        backgroundImage: { sm: 'none', md: `url("${backgroundImage}")` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Stack
        justifyContent="space-between"
        gap={5}
        p={{ xs: 2, sm: 4 }}
        maxWidth={534}
      >
        <BiampLogo width={100} />
        <Stack spacing={3} useFlexGap>
          <Typography variant="h1">{LL.title}</Typography>
          <Typography>{LL.subtitle}</Typography>
          <Stack spacing={2}>
            <Stack
              flexDirection="row"
              px={3}
              py={2.5}
              border={1}
              borderColor="text.secondary"
              borderRadius={1}
              gap={3}
            >
              <Stack justifyContent="center">
                <StartIcon width={52} height={52} />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h2">{LL.gettingStarted.title}</Typography>
                <Typography variant="body2">
                  {LL.gettingStarted.subtitle}
                </Typography>
                <Link
                  href={getPublicUrl().href}
                  underline="none"
                  color="success.main"
                  variant="body2"
                  fontWeight="600"
                >
                  {LL.gettingStarted.linkText}
                </Link>
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              px={3}
              py={2.5}
              border={1}
              borderColor="text.secondary"
              borderRadius={1}
              gap={3}
            >
              <Stack justifyContent="center">
                <FaqIcon width={52} height={52} />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h2">{LL.faq.title}</Typography>
                <Typography variant="body2">{LL.faq.subtitle}</Typography>
                <Link
                  href="https://support.biamp.com/Evoko"
                  underline="none"
                  color="success.main"
                  variant="body2"
                  fontWeight="600"
                >
                  {LL.faq.linkText}
                </Link>
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              px={3}
              py={2.5}
              border={1}
              borderColor="text.secondary"
              borderRadius={1}
              gap={3}
            >
              <Stack justifyContent="center">
                <LifeSaverIcon width={52} height={52} />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h2">
                  {LL.guidesAndSupport.title}
                </Typography>
                <Typography variant="body2">
                  {LL.guidesAndSupport.subtitle}
                </Typography>
                <Link
                  href="https://www.biamp.com/support/contact-support"
                  underline="none"
                  color="success.main"
                  variant="body2"
                  fontWeight="600"
                >
                  {LL.guidesAndSupport.linkText}
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Box
          component="ul"
          display="flex"
          alignItems="baseline"
          gap={2}
          color="text.secondary"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          <Typography component="li">
            <Link color="inherit" underline="hover" href="https://biamp.com">
              {now.getFullYear()} Biamp®
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              href="https://www.biamp.com/legal/privacy-policy"
            >
              Privacy
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              component={RouterLink}
              to="/eula"
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
      </Stack>
    </Page>
  );
}
