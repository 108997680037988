import { isObject } from '@evoko/utils';
import * as Sentry from '@sentry/react';
import { createZitadelAuth } from '@zitadel/react';
import { ErrorResponse, User, WebStorageStateStore } from 'oidc-client-ts';
import { getSignInCallbackUrl, getSignOutCallbackUrl } from './config';

export const zitadel = createZitadelAuth({
  authority: import.meta.env.VITE_ZITADEL_AUTHORITY_BASE_URL,
  client_id: import.meta.env.VITE_ZITADEL_CLIENT_ID,
  redirect_uri: getSignInCallbackUrl().href,
  post_logout_redirect_uri: getSignOutCallbackUrl().href,
  scope: 'openid profile email urn:zitadel:iam:user:metadata',
  response_mode: 'query',
  response_type: 'code',
  automaticSilentRenew: false,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  prompt: 'select_account',
});

export function getUserIdFromAuthUser(user: User) {
  const userMetadata = user?.profile?.['urn:zitadel:iam:user:metadata'];
  const base64UserId = Array.isArray(userMetadata)
    ? userMetadata.find((v) => isObject(v) && 'ewp_user_id' in v)?.ewp_user_id
    : undefined;

  if (!base64UserId || typeof base64UserId !== 'string') {
    return;
  }

  return atob(base64UserId);
}

/** Returns user from storage if found. If the access token has expired, silent
 * sign in is attempted. Function takes some care to ensure multiple silent sign
 * in attempts isn't made at the same time (in the same browser tab).
 */
export const getAuthUserWithQueuedSilentSignIn = (() => {
  type PendingRequest<TData> = {
    resolve: (data: TData) => unknown;
    reject: (error: unknown) => unknown;
  };

  class PendingQueue<TData> {
    private queue: PendingRequest<TData>[] = [];

    push(request: PendingRequest<TData>) {
      this.queue.push(request);
    }

    resolve(data: TData) {
      this.queue.forEach(({ resolve }) => resolve(data));
      this.queue = [];
    }

    reject(error: unknown) {
      this.queue.forEach(({ reject }) => reject(error));
      this.queue = [];
    }
  }

  const pendingQueue = new PendingQueue<User>();
  let isRenewing = false;

  return async () => {
    try {
      const user = await zitadel.userManager.getUser();
      // If there is no user, return null
      if (!user) {
        return null;
      }
      // If the access token is still valid, return user
      if (!user.expired) {
        return user;
      }
      // If the access token is expired and we're not renewing, initiate renew
      if (!isRenewing) {
        isRenewing = true;
        try {
          const newUser = await zitadel.userManager.signinSilent({
            login_hint: user.profile.email,
          });
          if (!newUser) {
            throw new Error('expected oidc user');
          }
          pendingQueue.resolve(newUser);
          return newUser;
        } catch (error) {
          if (
            !(
              error instanceof ErrorResponse &&
              (error.error === 'login_required' ||
                error.error === 'interaction_required')
            )
          ) {
            Sentry.captureException(error);
          }
          // remove user
          await zitadel.userManager.removeUser();
          throw error;
        } finally {
          isRenewing = false;
        }
      }
      // If renew is already initiated, return promise and append to queue
      return new Promise<User>((resolve, reject) => {
        pendingQueue.push({
          resolve: (newUser) => resolve(newUser),
          reject: (error) => reject(error),
        });
      });
    } catch (error) {
      pendingQueue.reject(error);
      throw error;
    }
  };
})();
