import {
  ErrorPage,
  ErrorPageDescription,
  ErrorPageDetails,
  ErrorPageTitle,
  Markdown,
  Page,
} from '@evoko/components';
import { Box } from '@mui/material';
import useSWR from 'swr';
import locale from '../../locale';

const LL = locale.pages.eula;

async function fetcher(url: string) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return await response.text();
}

export default function EulaPage() {
  const { data, isLoading, error } = useSWR('/eula.md', fetcher);

  if (error) {
    return (
      <ErrorPage title={LL.title}>
        <ErrorPageTitle>{LL.errorPage.title}</ErrorPageTitle>
        <ErrorPageDescription>{LL.errorPage.description}</ErrorPageDescription>
        {error instanceof Error && <ErrorPageDetails message={error.message} />}
      </ErrorPage>
    );
  }

  return (
    <Page
      title={LL.title}
      loading={isLoading}
      component="main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={2}
    >
      <Box maxWidth={720}>
        {data && <Markdown options={{ wrapper: 'article' }}>{data}</Markdown>}
      </Box>
    </Page>
  );
}
