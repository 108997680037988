export const StartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.9925.9525c-.2644.0056-.4763.2231-.4725.4875V12a.4828.4828 0 0 0 .2381.4219.4847.4847 0 0 0 .4837 0A.483.483 0 0 0 12.48 12V1.44a.4817.4817 0 0 0-.1407-.3469.4812.4812 0 0 0-.3468-.1406Zm-3.33.555a.4922.4922 0 0 0-.1744.0262C4.1118 3.002.96 7.1364.96 12c0 6.0919 4.948 11.04 11.04 11.04 6.0918 0 11.04-4.9481 11.04-11.04 0-4.8637-3.1519-8.9981-7.5282-10.4663a.4796.4796 0 0 0-.6075.302c-.0843.2512.0507.523.3038.6093C19.2 3.7838 22.08 7.5506 22.08 12c0 5.5725-4.5075 10.08-10.08 10.08S1.92 17.5725 1.92 12c0-4.4494 2.88-8.2163 6.8718-9.555.2232-.0731.362-.2925.3282-.525a.4778.4778 0 0 0-.4575-.4125Z"
    />
  </svg>
);
