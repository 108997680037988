import { isObject, type Json } from '@evoko/utils';
import * as Sentry from '@sentry/react';
import { useCallback, useMemo, useSyncExternalStore } from 'react';

function dispatchStorageEvent(key: string, newValue: string | null) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

function setItem(key: string, value: Json) {
  try {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
    dispatchStorageEvent(key, stringifiedValue);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function removeItem(key: string) {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
}

function getItem(key: string) {
  return window.localStorage.getItem(key);
}

function parseItem<T extends Json>(stringifiedValue: string | null): T | null {
  try {
    return stringifiedValue ? JSON.parse(stringifiedValue) : null;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
}

function subscribe(callback: () => void) {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
}

/** Hook for reading and writing any JSON compatible value to local storage. */
export function useLocalStorage<T extends Json>(key: string) {
  const getSnapshot = useCallback(() => getItem(key), [key]);
  const unparsedValue = useSyncExternalStore(subscribe, getSnapshot);

  const value = useMemo(() => parseItem<T>(unparsedValue), [unparsedValue]);

  const setValue = useCallback(
    (nextValue: T) => {
      if (
        nextValue === '' ||
        nextValue === undefined ||
        (isObject(nextValue) && Object.keys(nextValue).length === 0)
      ) {
        removeItem(key);
        return;
      }
      setItem(key, nextValue);
    },
    [key],
  );

  return [value, setValue] as const;
}
