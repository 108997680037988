import { User } from 'oidc-client-ts';

type AuthErrorType =
  | 'init'
  | 'sign-in-redirect'
  | 'sign-out-redirect'
  | 'sign-in-callback'
  | 'sign-out-callback';

export class AuthError extends Error {
  public type: AuthErrorType;
  public sentryId?: string;
  constructor(args: {
    message: string;
    cause?: unknown;
    type: AuthErrorType;
    sentryId?: string;
  }) {
    super(args.message);
    this.name = 'AuthError';
    this.type = args.type;
    this.cause = args.cause;
    this.sentryId = args.sentryId;
  }
}

type State =
  | {
      status:
        | 'uninitialized'
        | 'loading-initalize'
        | 'loading-sign-in'
        | 'loading-callback'
        | 'unauthenticated';
      user: null;
      error: null;
    }
  | {
      status: 'loading-sign-out' | 'authenticated';
      user: User;
      error: null;
    }
  | {
      status: 'error-initalize' | 'error-sign-in' | 'error-callback';
      user: null;
      error: AuthError;
    }
  | {
      status: 'error-sign-out';
      user: User;
      error: AuthError;
    };

type Action =
  | { type: 'INITALIZATION_INITIATED' }
  | { type: 'CALLBACK_INITIATED' }
  | { type: 'SIGN_IN_INITIATED' }
  | { type: 'SIGN_OUT_INITIATED' }
  | { type: 'SIGNED_IN'; data: { user: User } }
  | { type: 'SIGNED_OUT' }
  | { type: 'ERROR'; error: AuthError }
  | { type: 'USER_LOADED'; data: { user: User } }
  | { type: 'USER_UNLOADED' };

export function reducer(state: State, action: Action): State {
  switch (state.status) {
    case 'uninitialized': {
      switch (action.type) {
        case 'INITALIZATION_INITIATED': {
          return { ...state, status: 'loading-initalize' };
        }
        case 'CALLBACK_INITIATED': {
          return { ...state, status: 'loading-callback' };
        }
        default:
          return state;
      }
    }

    case 'loading-initalize':
    case 'loading-callback':
    case 'loading-sign-in': {
      switch (action.type) {
        case 'SIGNED_OUT': {
          return { ...state, status: 'unauthenticated' };
        }
        case 'SIGNED_IN': {
          const { user } = action.data;
          return { ...state, status: 'authenticated', user };
        }
        case 'ERROR': {
          const { error } = action;
          if (state.status === 'loading-initalize') {
            return { ...state, status: 'error-initalize', error };
          }
          if (state.status === 'loading-callback') {
            return { ...state, status: 'error-callback', error };
          }
          return { ...state, status: 'error-sign-in', error };
        }
        default:
          return state;
      }
    }

    case 'loading-sign-out': {
      switch (action.type) {
        case 'SIGNED_OUT': {
          return { ...state, status: 'unauthenticated', user: null };
        }
        case 'ERROR': {
          const { error } = action;
          return { ...state, status: 'error-sign-out', error };
        }
        default:
          return state;
      }
    }

    case 'authenticated': {
      switch (action.type) {
        case 'SIGN_OUT_INITIATED': {
          return { ...state, status: 'loading-sign-out' };
        }
        case 'SIGN_IN_INITIATED': {
          return { ...state, status: 'loading-sign-in', user: null };
        }
        case 'USER_LOADED': {
          const { user } = action.data;
          if (state.user === user) {
            return state;
          }
          return { ...state, user };
        }
        case 'USER_UNLOADED': {
          return { ...state, status: 'unauthenticated', user: null };
        }
        default:
          return state;
      }
    }

    case 'unauthenticated': {
      switch (action.type) {
        case 'SIGN_IN_INITIATED': {
          return { ...state, status: 'loading-sign-in' };
        }
        default:
          return state;
      }
    }

    case 'error-initalize':
    case 'error-sign-in':
    case 'error-callback': {
      switch (action.type) {
        case 'SIGN_IN_INITIATED': {
          return { ...state, status: 'loading-sign-in', error: null };
        }
        default:
          return state;
      }
    }

    case 'error-sign-out': {
      switch (action.type) {
        case 'SIGN_OUT_INITIATED': {
          return { ...state, status: 'loading-sign-out', error: null };
        }
        default:
          return state;
      }
    }
  }
}

export const initialState: State = {
  status: 'uninitialized',
  user: null,
  error: null,
};
