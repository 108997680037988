import {
  AppleAppStoreBadge,
  BiampLogo,
  GooglePlayStoreBadge,
  Page,
} from '@evoko/components';
import { Box, Link, Stack, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react';
import locale from '../../locale';
import biampAppImage from './assets/biamp-app.png';
import backgroundImage from './assets/resource-page-background.jpg';

const LL = locale.pages.resource;

export default function ResourcePage() {
  useEffect(() => {
    document.body.style.setProperty('overscroll-behavior', 'none');

    return () => {
      document.body.style.removeProperty('overscroll-behavior');
    };
  }, []);

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100vh"
      height="100%"
      px={4}
      pt={4}
      sx={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box
        maxWidth={600}
        display="flex"
        flexDirection="column"
        height="100%"
        gap={5}
      >
        <Box maxWidth={100} width="100">
          <BiampLogo aria-label="biamp logo" />
        </Box>
        <Stack spacing={3} useFlexGap>
          <Typography variant="h1">{LL.title}</Typography>
          <Typography>{LL.subtitle}</Typography>
          <Stack spacing={2} direction="row" useFlexGap>
            <Link href="https://apps.apple.com/us/app/evoko-workplace/id1615758561">
              <AppleAppStoreBadge aria-hidden width="100%" />
              <span style={visuallyHidden}>Apple app store</span>
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=se.evoko.workplace">
              <GooglePlayStoreBadge aria-hidden width="100%" />
              <span style={visuallyHidden}>Google play store</span>
            </Link>
          </Stack>
        </Stack>
        <Box
          component="img"
          src={biampAppImage}
          alt="Biamp app illustration"
          width="100%"
          mt="auto"
        />
      </Box>
    </Page>
  );
}
