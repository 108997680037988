import { useAuth } from '@evoko/api';
import { Page } from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Collapse,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import background from '../../assets/landing-page-background.jpg';
import { BiampBlock } from '../../components/BiampBlock';
import locale from '../../locale';

const LL = locale.pages.landing;

export default function LandingPage() {
  const { authenticated, loading, error, signInRedirect } = useAuth();
  const now = new Date();

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      p={2}
      sx={{
        backgroundImage: { xs: 'none', sm: `url("${background}")` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <BiampBlock>
        <Stack gap={3}>
          <Typography variant="h0" component="h1" lineHeight={1}>
            {LL.title}
          </Typography>
          <Typography variant="body1">{LL.subtitle}</Typography>
          {authenticated ? (
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to="/profile"
              sx={{ width: 'max-content' }}
            >
              {LL.profile}
            </Button>
          ) : (
            <Stack spacing={2}>
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                onClick={() => signInRedirect({ errorPolicy: 'catch' })}
                sx={{ width: 'max-content' }}
              >
                {LL.signInOrRegister}
              </LoadingButton>
              <Collapse in={!!error && error.type === 'sign-in-redirect'}>
                <Alert severity="error">{LL.alerts.signInRedirectFailed}</Alert>
              </Collapse>
            </Stack>
          )}
        </Stack>
        <Stack
          component="ul"
          direction="row"
          alignItems="baseline"
          gap={2}
          color="text.secondary"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          <Typography component="li">
            <Link color="inherit" underline="hover" href="https://biamp.com">
              {now.getFullYear()} Biamp®
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              href="https://www.biamp.com/legal/privacy-policy"
            >
              Privacy
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              component={RouterLink}
              to="/eula"
            >
              Terms of Service
            </Link>
          </Typography>
        </Stack>
      </BiampBlock>
    </Page>
  );
}
