import { BrowserOptions, init } from '@sentry/react';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

const SENTRY_RELEASE = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export function initializeSentry(options?: BrowserOptions) {
  if (import.meta.env.PROD) {
    init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release: SENTRY_RELEASE,
      // FIXME: disable sentry tunnel since there is no endpoint in new backend
      // tunnel: getBackendUrl('sentry').href,
      ...options,
    });
  }
}
