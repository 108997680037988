import * as Sentry from '@sentry/react';
import { ErrorResponse, type User } from 'oidc-client-ts';
import { useEffect, useReducer, useRef } from 'react';
import { z } from 'zod';
import { getSignInCallbackUrl, getSignOutCallbackUrl } from '../config';
import { getUserIdFromAuthUser, zitadel } from '../zitadel';
import {
  AuthContext,
  type SignInRedirectOptions,
  type SignOutRedirectOptions,
} from './AuthContext';
import { AuthError, initialState, reducer } from './reducer';

const userStateSchema = z
  .object({ returnTo: z.string().url().optional() })
  .optional();

function appendErrorMessage(base: string, error: unknown) {
  return `${base}: ${
    error instanceof Error ? error.message : 'something went wrong'
  } `;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const hasInitialized = useRef(false);

  // Handle init and callbacks
  useEffect(() => {
    // Hacky way for making sure this effect only runs once in dev
    if (hasInitialized.current) {
      return;
    }
    hasInitialized.current = true;

    async function signInCallback() {
      try {
        dispatch({ type: 'CALLBACK_INITIATED' });
        let user: User | null | void = null;
        user = await zitadel.userManager.signinCallback();
        if (!user) {
          user = await zitadel.userManager.getUser();
        }
        if (!user) {
          throw new Error('expected user to be found');
        }
        const userState = userStateSchema.parse(user.state);
        if (userState?.returnTo) {
          window.location.replace(userState.returnTo);
          return;
        }
        dispatch({ type: 'SIGNED_IN', data: { user } });
      } catch (err) {
        let sentryId: string | undefined;
        if (
          !(
            err instanceof ErrorResponse &&
            (err.error === 'login_required' ||
              err.error === 'interaction_required')
          )
        ) {
          sentryId = Sentry.captureException(err);
        }
        const error = new AuthError({
          message: appendErrorMessage('Sign in callback failed', err),
          type: 'sign-in-callback',
          cause: err,
          sentryId,
        });
        dispatch({ type: 'ERROR', error });
      }
    }

    async function signOutCallback() {
      try {
        dispatch({ type: 'CALLBACK_INITIATED' });
        await zitadel.userManager.signoutCallback();
        dispatch({ type: 'SIGNED_OUT' });
      } catch (err) {
        const error = new AuthError({
          message: appendErrorMessage('Sign out callback failed', err),
          type: 'sign-out-callback',
          cause: err,
          sentryId: Sentry.captureException(err),
        });
        dispatch({ type: 'ERROR', error });
      }
    }

    async function initalize() {
      try {
        dispatch({ type: 'INITALIZATION_INITIATED' });
        let user = await zitadel.userManager.getUser();

        // Make attempt at silent sign in to get a new access token if expired
        if (user?.expired) {
          try {
            user = await zitadel.userManager.signinSilent({
              login_hint: user.profile.email,
            });
          } catch {
            // Ignore any error and remove user for storage if it fails
            await zitadel.userManager.removeUser().catch();
            user = null;
          }
        }
        if (user) {
          dispatch({ type: 'SIGNED_IN', data: { user } });
          return;
        }
        dispatch({ type: 'SIGNED_OUT' });
      } catch (err) {
        const error = new AuthError({
          message: appendErrorMessage('Init failed', err),
          type: 'init',
          cause: err,
          sentryId: Sentry.captureException(err),
        });
        dispatch({ type: 'ERROR', error });
      }
    }

    function removeTrailingSlash(str: string) {
      return str.endsWith('/') ? str.slice(0, -1) : str;
    }

    switch (removeTrailingSlash(window.location.pathname)) {
      case removeTrailingSlash(getSignInCallbackUrl().pathname): {
        signInCallback();
        break;
      }
      case removeTrailingSlash(getSignOutCallbackUrl().pathname): {
        signOutCallback();
        break;
      }
      default: {
        initalize();
      }
    }
  }, []);

  // Tap into any relevant events
  // NOTE: the `addUserSignedIn`, `addUserSignedOut` and `addUserSessionChanged`
  // events requires `monitorSession` to be `true` however regardless they haven't
  // fired for me when testing. Skipping.
  useEffect(() => {
    function handleUserLoaded(user: User) {
      dispatch({ type: 'USER_LOADED', data: { user } });
    }

    function handleUserUnloaded() {
      dispatch({ type: 'USER_UNLOADED' });
    }

    const { events } = zitadel.userManager;
    events.addUserLoaded(handleUserLoaded);
    events.addUserUnloaded(handleUserUnloaded);
    () => {
      events.removeUserLoaded(handleUserLoaded);
      events.removeUserUnloaded(handleUserUnloaded);
    };
  }, []);

  // Set user id for sentry
  useEffect(() => {
    if (!state.user) {
      Sentry.setUser(null);
      return;
    }
    const userId = getUserIdFromAuthUser(state.user);
    if (userId) {
      Sentry.setUser({ id: userId });
    }
  }, [state.user]);

  async function signInRedirect({
    loginHint,
    returnTo,
    errorPolicy = 'throw',
  }: SignInRedirectOptions) {
    if (
      state.status !== 'unauthenticated' &&
      state.status !== 'authenticated' &&
      state.status !== 'error-initalize' &&
      state.status !== 'error-sign-in' &&
      state.status !== 'error-callback'
    ) {
      return;
    }
    try {
      dispatch({ type: 'SIGN_IN_INITIATED' });
      await zitadel.userManager.signinRedirect({
        redirect_uri: getSignInCallbackUrl().href,
        login_hint: loginHint ? encodeURI(loginHint) : undefined,
        state: returnTo ? { returnTo: returnTo.href } : undefined,
      });
    } catch (err) {
      const error = new AuthError({
        message: appendErrorMessage('Sign in redirect failed', err),
        type: 'sign-in-redirect',
        cause: err,
        sentryId: Sentry.captureException(err),
      });
      dispatch({ type: 'ERROR', error });
      if (errorPolicy === 'throw') {
        throw error;
      }
    }
  }

  async function signOutRedirect(
    options: SignOutRedirectOptions | undefined = { errorPolicy: 'throw' },
  ) {
    if (state.status !== 'authenticated' && state.status !== 'error-sign-out') {
      return;
    }
    try {
      dispatch({ type: 'SIGN_OUT_INITIATED' });
      await zitadel.userManager.signoutRedirect({
        post_logout_redirect_uri: getSignOutCallbackUrl().href,
      });
    } catch (err) {
      const error = new AuthError({
        message: appendErrorMessage('Sign out redirect failed', err),
        type: 'sign-out-redirect',
        cause: err,
        sentryId: Sentry.captureException(err),
      });
      dispatch({ type: 'ERROR', error });
      if (options.errorPolicy === 'throw') {
        throw error;
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!state.user && !state.user.expired,
        loading:
          state.status.startsWith('loading') ||
          state.status === 'uninitialized',
        error: state.error,
        signInRedirect,
        signOutRedirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
