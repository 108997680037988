import { Role, getAdminUrl, hasRole } from '@evoko/api';
import {
  OrganizationCard,
  OrganizationCardActions,
  OrganizationCardDescription,
  OrganizationCardTitle,
} from '@evoko/components';
import { Button } from '@mui/material';
import {
  MembershipStatus,
  type MembershipFieldsFragment,
} from '../../../../../generated/graphql';
import locale from '../../../../../locale';

const LL = locale.pages.profile.organizationList.organizationCard;

type MembershipOrganizationCardProps = {
  membership: MembershipFieldsFragment;
};

export function MembershipOrganizationCard({
  membership,
}: MembershipOrganizationCardProps) {
  const admin = hasRole(membership.role, Role.DeskAdmin);

  return (
    <OrganizationCard
      key={membership.orgId}
      logoUrl={membership.organization?.logoUrl ?? undefined}
      logoDimmed={membership.status !== MembershipStatus.Active}
    >
      <OrganizationCardTitle>
        {membership.organization?.name}
      </OrganizationCardTitle>
      {membership.status === MembershipStatus.Requested && (
        <OrganizationCardDescription color="warning.main">
          {LL.awaitingApproval}
        </OrganizationCardDescription>
      )}
      {admin && (
        <OrganizationCardActions>
          <Button
            variant="contained"
            color="primary"
            href={getAdminUrl(membership.organization?.domain).href}
          >
            {LL.dashboardButton}
          </Button>
        </OrganizationCardActions>
      )}
    </OrganizationCard>
  );
}
