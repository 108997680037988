import { createContext } from 'react';
import { AuthError } from './reducer';

export type SignInRedirectOptions = {
  /** Return the user to the provided URL after successful sign in. */
  returnTo?: URL;
  /** Prefill the users email in the sign in or registration form. */
  loginHint?: string;
  /**
   * Determines if errors should be thrown or caught if the promise rejects.
   * Regardless of policy the error will also be populated in `AuthContextValue.error`.
   * @default 'throw'
   */
  errorPolicy?: 'throw' | 'catch';
};

export type SignOutRedirectOptions = {
  /**
   * Determines if errors should be thrown or caught if the promise rejects.
   * Regardless of policy the error will also be populated in `AuthContextValue.error`.
   * @default 'throw'
   */
  errorPolicy?: 'throw' | 'catch';
};

type AuthContextValue = {
  authenticated: boolean;
  loading: boolean;
  error: AuthError | null;
  signInRedirect: (options: SignInRedirectOptions) => Promise<void>;
  signOutRedirect: (options?: SignOutRedirectOptions) => Promise<void>;
};

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);
