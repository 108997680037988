export const BiampLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="32"
    viewBox="0 0 96 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6646 15.5842C16.6646 20.1638 13.2219 23.705 8.93345 23.705C7.17998 23.705 5.71688 23.2495 4.51563 22.471V23.38H0V2.87967L4.51563 0.313698V8.72802C5.7179 7.94844 7.17998 7.49394 8.93345 7.49394C13.2209 7.49394 16.6646 11.0678 16.6646 15.5842ZM12.2142 15.5842C12.2142 13.3097 10.5249 11.3276 8.18662 11.3276C6.85496 11.3276 5.52228 11.7821 4.51563 13.115V18.085C5.52228 19.4495 6.85496 19.905 8.18662 19.905C10.5249 19.905 12.2142 17.8903 12.2142 15.5842Z"
      fill="white"
    />
    <path d="M23.7825 7.81901H19.2668V23.381H23.7825V7.81901Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.981 23.381V7.82005H38.4654V8.72904C37.2642 7.94947 35.8031 7.49497 34.0486 7.49497C29.7602 7.49497 26.2848 11.0362 26.2848 15.6158C26.2848 20.1322 29.7602 23.7061 34.0486 23.7061C35.8021 23.7061 37.2642 23.2505 38.4654 22.472V23.381H42.981ZM38.4654 13.115V18.085C37.4588 19.4169 36.1271 19.8724 34.7954 19.8714C32.4245 19.8714 30.7668 17.8893 30.7668 15.6148C30.7668 13.3097 32.4245 11.295 34.7954 11.295C36.1271 11.295 37.4588 11.7505 38.4654 13.115Z"
      fill="white"
    />
    <path
      d="M72.0555 14.8362V23.381H67.5409V15.2275C67.5409 12.7574 66.3386 11.4265 64.2285 11.4265C63.2851 11.4265 62.2468 11.8484 61.3044 13.1487L61.3047 13.1515C61.3698 13.7029 61.4348 14.2533 61.4348 14.8383V23.383H56.9192V15.2296C56.9192 12.7594 55.7169 11.4285 53.5732 11.4285C52.697 11.4285 51.7219 11.9482 50.8121 13.1823V23.381H46.2975V7.81903H50.8121V8.76064C51.7861 7.85164 52.8264 7.3309 54.6461 7.3309C56.6278 7.3309 58.3813 8.1115 59.6161 9.54021C61.2728 8.01265 62.8317 7.3309 65.2688 7.3309C69.0672 7.3309 72.0555 10.2882 72.0555 14.8362Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.5585 23.7061C87.846 23.7061 91.2897 20.1322 91.2897 15.6158C91.2897 11.0352 87.846 7.49395 83.5585 7.49395C81.8051 7.49395 80.343 7.94845 79.1407 8.72803V7.81903H74.6251V30.8863L79.1407 28.3203V22.472C80.344 23.2505 81.8051 23.7061 83.5585 23.7061ZM82.8107 11.296C85.149 11.296 86.8393 13.3097 86.8393 15.6158C86.8393 17.8903 85.15 19.8724 82.8107 19.8734C81.478 19.8734 80.1464 19.4189 79.1397 18.086V13.1161C80.1464 11.7505 81.479 11.296 82.8107 11.296Z"
      fill="white"
    />
    <path
      d="M90.9131 2.57889V3.965H91.0236V2.57889H91.5423V2.47685H90.3945V2.57889H90.9131Z"
      fill="white"
    />
    <path
      d="M91.8668 2.47685V3.965H91.9731V2.67243L92.5619 3.52918H92.5704L93.1592 2.67243V3.965H93.2697V2.47685H93.1655L92.5683 3.35698L91.971 2.47685H91.8668Z"
      fill="white"
    />
    <path
      d="M96 21.5586C96 22.7499 95.0341 23.716 93.8431 23.716C92.652 23.716 91.6861 22.7499 91.6861 21.5586C91.6861 20.3674 92.652 19.4013 93.8431 19.4013C95.0341 19.4023 96 20.3674 96 21.5586Z"
      fill="#D22730"
    />
  </svg>
);
