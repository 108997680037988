import { type PaletteMode } from '@mui/material';
import { alpha, createTheme as muiCreateTheme } from '@mui/material/styles';

export const colors = {
  black: '#000000',
  white: '#ffffff',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#c9c9c9',
    400: '#878787',
    500: '#646464',
    600: '#464646',
    700: '#333333',
    800: '#222222',
    900: '#111111',
  },
  biamp: '#d22730',
  successDark: '#03c93a',
  successMain: '#00e941',
  successLight: '#eafef0',
  errorDark: '#c12e43',
  errorLight: '#ffedf0',
  errorMain: '#ff1744',
  warningDark: '#ca9529',
  warningLight: '#fffaea',
  warningMain: '#ffb800',
  infoDark: '#0391ff',
  infoLight: '#ebf7ff',
} as const;

export const appBarHeight = 64;
const drawerWidth = 300;

export const createTheme = (mode: PaletteMode = 'light') =>
  muiCreateTheme({
    spacing: 8,
    shape: { borderRadius: 4 },
    palette: {
      mode,
      // Mode agnostic palette values
      secondary: {
        contrastText: colors.grey[900],
        dark: colors.white,
        light: colors.white,
        main: colors.white,
      },
      success: {
        contrastText: colors.grey[900],
        dark: colors.successDark,
        light: colors.successLight,
        main: colors.successMain,
      },
      warning: {
        contrastText: colors.grey[900],
        dark: colors.warningDark,
        light: colors.warningLight,
        main: colors.warningMain,
      },
      error: {
        contrastText: colors.white,
        dark: colors.errorDark,
        light: colors.errorLight,
        main: colors.errorMain,
      },
      info: {
        contrastText: colors.white,
        dark: colors.infoDark,
        light: colors.infoDark,
        main: colors.infoDark,
      },
      grey: {
        ...colors.grey,
      },
      common: {
        black: colors.black,
        white: colors.white,
      },
      biamp: {
        contrastText: colors.white,
        dark: colors.biamp,
        light: colors.biamp,
        main: colors.biamp,
      },

      // Mode specific palette values
      ...(() => {
        switch (mode) {
          case 'light': {
            return {
              primary: {
                contrastText: colors.white,
                dark: colors.grey[900],
                light: colors.grey[900],
                main: colors.grey[900],
              },
              text: {
                primary: colors.grey[900],
                secondary: colors.grey[400],
                disabled: alpha(colors.grey[900], 0.4),
                sidebar: colors.white,
              },
              background: {
                default: colors.grey[100],
                paper: colors.white,
                success: colors.successLight,
                error: colors.errorLight,
                warning: colors.warningLight,
                info: colors.infoLight,
              },
            };
          }
          case 'dark': {
            return {
              primary: {
                contrastText: colors.grey[900],
                dark: colors.white,
                light: colors.white,
                main: colors.white,
              },
              text: {
                primary: colors.white,
                secondary: colors.grey[400],
                disabled: alpha(colors.white, 0.4),
                sidebar: colors.white,
              },
              background: {
                default: colors.grey[900],
                paper: colors.grey[800],
                success: colors.successLight,
                error: colors.errorLight,
                warning: colors.warningLight,
                info: colors.infoLight,
              },
            };
          }
        }
      })(),
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 600,
      h0: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: '3.5rem',
        fontWeight: 500,
        letterSpacing: -0.3,
        lineHeight: 1.5,
      },
      h1: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: '1.75rem',
        fontWeight: 500,
        letterSpacing: -0.4,
        lineHeight: 1.5,
      },
      h2: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: '1.25rem',
        fontWeight: 600,
        letterSpacing: -0.3,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: -0.2,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        letterSpacing: -0.2,
        lineHeight: 1.5,
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 400,
        letterSpacing: -0.2,
        lineHeight: 1.5,
      },
      subtitle1: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
      },
      button: {
        fontSize: '0.875rem',
        letterSpacing: -0.2,
        fontWeight: 700,
        textTransform: 'none',
        lineHeight: 1.5,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url(/open-sans-regular.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: italic;
          font-display: swap;
          src: url(/open-sans-regular-italic.woff2) format('woff2');
        }
        
        @font-face {
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url(/open-sans-semibold.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: italic;
          font-display: swap;
          src: url(/open-sans-semibold-italic.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Montserrat';
          font-weight: 500;
          font-style: normal;
          font-display: swap;
          src: url(/Montserrat-Medium.ttf) format('truetype');
        }

        @font-face {
          font-family: 'Montserrat';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url(/Montserrat-SemiBold.ttf) format('truetype');
        }

        :root {
          color-scheme: ${(() => {
            switch (mode) {
              case 'light':
                return 'light';
              case 'dark':
                return 'dark';
              default:
                return 'normal';
            }
          })()};
        }
    `,
      },
      MuiAlert: {
        styleOverrides: {
          standard: ({ theme }) => ({
            color: theme.palette.common.black,
            border: `1px solid`,
          }),
          standardError: ({ theme }) => ({
            borderColor: theme.palette.error.main,
            backgroundColor: colors.errorLight,
          }),
          standardInfo: ({ theme }) => ({
            borderColor: theme.palette.info.main,
            backgroundColor: colors.infoLight,
          }),
          standardSuccess: ({ theme }) => ({
            borderColor: theme.palette.success.main,
            backgroundColor: colors.successLight,
          }),
          standardWarning: ({ theme }) => ({
            borderColor: theme.palette.warning.main,
            backgroundColor: colors.warningLight,
          }),
        },
      },
      MuiButton: {
        variants: [
          {
            props: { size: 'large' },
            style: ({ theme }) => ({
              minHeight: theme.spacing(8),
            }),
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: { backgroundImage: 'none' },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: theme.typography.h1.fontSize,
            fontWeight: theme.typography.h1.fontWeight,
            letterSpacing: theme.typography.h1.letterSpacing,
            lineHeight: theme.typography.h1.lineHeight,
            padding: theme.spacing(2, 3, 1),
            '& + .MuiDialogContent-root': {
              paddingTop: `${theme.spacing(1)} !important`,
            },
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: theme.spacing(2, 3, 1),
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& *:nth-last-of-type(n + 3):first-of-type': {
              marginRight: 'auto',
            },
            padding: theme.spacing(1, 3, 2),
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: { width: drawerWidth },
          paper: { width: drawerWidth, backgroundImage: 'none' },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            flex: 1,
          },
          labelPlacementStart: {
            display: 'flex',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              '&.Mui-focusVisible': {
                backgroundColor: theme.palette.action.focus,
              },
            },
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderBottomColor: theme.palette.divider,
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
    },
  });
