import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import locale from '../../../../../locale';

const LL = locale.pages.profile.organizationList.addOrganizationButton;

export function AddOrganizationButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        color="secondary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography variant="button">{LL.add}</Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ '& ul': { p: 0 } }}
      >
        <MenuItem sx={{ p: 3, pb: 2 }} component={Link} to="join-organization">
          {LL.join}
        </MenuItem>
        <MenuItem
          sx={{ p: 3, pt: 2 }}
          component={Link}
          to="create-organization"
        >
          {LL.create}
        </MenuItem>
      </Menu>
    </>
  );
}
