import { AuthError } from '@evoko/api';
import locale from '../locale';
import {
  ErrorPage,
  ErrorPageDescription,
  ErrorPageDetails,
  ErrorPageTitle,
} from './ErrorPage';

const LL = locale.signInRedirectErrorPage;

type SignInRedirectErrorPageProps = {
  error: AuthError;
};

export function SignInRedirectErrorPage({
  error,
}: SignInRedirectErrorPageProps) {
  return (
    <ErrorPage title={LL.title}>
      <ErrorPageTitle>{LL.title}</ErrorPageTitle>
      <ErrorPageDescription>{LL.description}</ErrorPageDescription>
      <ErrorPageDetails message={error.message} sentryId={error.sentryId} />
    </ErrorPage>
  );
}
