export const ErrorCode = {
  NotFound: 'notFound',
  Banned: 'banned',
  Pending: 'pending',
  AlreadyMember: 'alreadyMember',
  PermissionDenied: 'permissionDenied',
  Duplicate: 'dup',
  ActivationPending: 'activationPending',
  AuthorizationPending: 'authorizationPending',
  ExpiredToken: 'expiredToken',
  SlowDown: 'slowDown',
  Conflict: 'conflict',
  EmailNotAvailable: 'emailNotAvailable',
  LicenseAlreadyActivated: 'licenseAlreadyActivated',
  InvalidArgument: 'INVALID_ARGUMENT',
  Internal: 'INTERNAL',
} as const;

/** Represents a known backend error code */
export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];
