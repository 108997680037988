export function VisitorManagementIllustration(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      fill="none"
      aria-hidden
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81 52c3-4 8-6 14-6 6-1 11 1 13 3 8 8 6 19-1 30l-6 8-14-7c-6-3-10-7-12-11-1-4 0-10 6-17Zm32 31-5 6 13 3v-1l2-3 3-3c7-1 14 2 19 6 6 4 11 9 14 13l10 20 5 11a737 737 0 0 0 15 31c4 6 7 8 10 9 7 1 12 0 15-1 2-2 5-5 7-10v-7l-3-12h-1v-1l-3-10-1-5c0-2 0-4 2-5a5 5 0 0 1 5-1l3 2 4 5v1l2 3a150 150 0 0 0 4-9l4-8a99 99 0 0 1 7-13l4-2h3l2 2v4l-2 9v2l-1 3 5-6 4-2h2l3 2 1 4-1 3a75 75 0 0 1-3 13 84 84 0 0 1 9-2 4 4 0 0 1 3 3v2l-2 4-7 9-15 22-4 12v1l-5 15c-2 5-5 10-9 14s-10 6-18 6c-11 1-24-6-33-12l-14-9v-1h-1l-4-3v6c-1 5 0 12 1 19l6 20c3 6 6 11 10 13l-4 6c-5-4-9-10-12-16a101 101 0 0 1-7-50l3-6 2-1 2 1 7 6a1039 1039 0 0 0 14 9c10 7 21 12 30 12 6-1 10-3 14-5 3-3 5-7 7-12l5-14 4-14 15-23h1a738 738 0 0 0-1-3h-2l-7 4 3-8v-1a195 195 0 0 0 6-17 65 65 0 0 0-9 10l-2 2v1l-5 7-1-9 1-9a191 191 0 0 1 1-5l-4 8-1 2-4 8c0 2-2 3-4 4l-4-2-2-2a46 46 0 0 1-3-4l-1-1 3 8v1a489 489 0 0 1 5 14c1 4 1 8-1 12-3 5-6 9-10 12-5 2-11 3-20 1-5-1-9-6-13-11a139 139 0 0 1-16-32l-5-12c-3-7-6-14-10-18-2-4-6-8-11-12-5-3-10-5-14-4l-1 1-2 7a344 344 0 0 0-4 27l-2 19a76 76 0 0 1-3 11l-1 1-2 1c-2 1-4 0-4-1l-2-2-5-7-7-14-3-7a235 235 0 0 0-17-27l-3 2-5 5-11 16c-8 14-15 31-20 49-4 17-4 34 1 46 3 6 7 11 13 14 5 3 11 5 20 6l-4-9-2-4-2-2-3 1-1 1-2-1c-3-2-5-6-7-10l-2-11c0-10 4-22 9-33a145 145 0 0 1 12-21l5 4-5 7a208 208 0 0 1 41 10l4 3 1 3 1 3 2 9a244 244 0 0 1 2 49l-2 9c-1 2-3 5-7 5-4 1-11 0-18-1-6-1-13-3-17-6v1c-12 0-21-3-29-7-7-4-12-10-15-17-6-14-5-33-1-51a195 195 0 0 1 32-68l6-5 6-3h4l3 2 3 4a45 45 0 0 0 12-9c-4-2-9-3-13-6-6-3-12-9-14-15-2-7 0-15 7-23 5-6 12-8 19-9 6 0 14 1 18 5 11 11 7 27 0 39Zm-25 23 10 19 3 7 7 14 3 5 1-6 3-19a5592 5592 0 0 1 4-28l-16-4a70 70 0 0 1-15 12Zm25 47Zm-51 21c-4 10-8 21-8 30a24 24 0 0 0 4 14h7l5 5 2 5 6 12 15 4c7 1 13 2 17 1v-1l2-7 1-23a237 237 0 0 0-5-35h-1v-1l-1-1-5-2-13-3c-9-2-17-4-23-4l-3 6Zm48 71Z"
      />
    </svg>
  );
}
