import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  type ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, type ErrorResponse } from '@apollo/client/link/error';
import { MockLink, type MockedResponse } from '@apollo/client/testing';
import { isPrimitive } from '@evoko/utils';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import { User } from 'oidc-client-ts';
import {
  getOverviewSession,
  isOverviewAuthenticated,
  parseOverviewSession,
  removeOverviewSession,
} from './auth';
import { getBackendUrl } from './config';
import { getAuthUserWithQueuedSilentSignIn } from './zitadel';

export const CLIENT_META_HEADER_NAME = 'Evoko-Client';

export function getClientMetaHeaderValue(client: 'web' | 'overview') {
  return `${client}/${
    String(import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA).slice(0, 7) || 'unknown'
  }`;
}

export const createUserAuthLink = () => {
  let user: User | null = null;

  const innerAuthLink = setContext((_, context) => {
    // If cached user with valid access token is found, add access token to auth header
    if (user && !user.expired) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
    }
    // ... if not, check storage along with eventual silent sign in to get user
    // with valid access token
    return getAuthUserWithQueuedSilentSignIn().then((u) => {
      if (!u) {
        return context;
      }
      user = u;
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
    });
  });

  const innerErrorLink = onError(({ networkError, forward, operation }) => {
    // If the server returns a 401
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      const context = operation.getContext();
      const http401RetryCount = context?.http401RetryCount ?? 0;
      // ... and we have a user along with a operation that has only been attempted once
      if (user && http401RetryCount < 1) {
        // ... then invalidate cached user if the access token match the
        // one used in the 401 operation
        if (context.headers.Authorization === `Bearer ${user.access_token}`) {
          user = null;
        }
        // ... and increment retry count and retry the operation
        operation.setContext({
          ...context,
          http401RetryCount: http401RetryCount + 1,
        });
        return forward(operation);
      }
    }
  });

  return from([innerErrorLink, innerAuthLink]);
};

export const createOverviewAuthLink = () => {
  const innerAuthLink = setContext((_, context) => {
    const sessionString = getOverviewSession();
    const session = parseOverviewSession(sessionString);

    if (!isOverviewAuthenticated(session)) {
      return context;
    }

    return {
      ...context,
      headers: {
        ...context.headers,
        Authorization: `Bearer ${session.accessToken}`,
      },
    };
  });

  const innerErrorLink = onError(({ networkError }) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      removeOverviewSession();
    }
  });

  return from([innerErrorLink, innerAuthLink]);
};

type CreateSentryLinkOptions = {
  /** If returns `true`, the error will not be reported to sentry. */
  ignoreLoggingErrorToSentry?: (
    error: Pick<ErrorResponse, 'operation' | 'graphQLErrors'>,
  ) => boolean;
};

/**
 * Creates apollo link for sending errors to sentry.
 *
 * NOTE: we're not sending any variables for the operation to sentry as it could
 * contain sensitive data which requires careful marsking based on `@sensitive`
 * directive. Consider adding it if masking can be done with confidence and
 * without too much overhead.
 */
export const createSentryLink = (options: CreateSentryLinkOptions = {}) =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (options?.ignoreLoggingErrorToSentry?.({ operation, graphQLErrors })) {
      return;
    }

    const scope = new Sentry.Scope();
    scope.setLevel('debug');
    scope.setTag('operation_name', operation.operationName);
    scope.setExtra('operation_body', operation.query.loc?.source.body);

    for (const definition of operation.query.definitions) {
      if (definition.kind === 'OperationDefinition') {
        scope.setTag('operation_type', definition.operation);
        break;
      }
    }

    const context = operation.getContext();
    if (context?.response instanceof Response) {
      scope.setTag('request_id', context.response.headers.get('X-Request-Id'));
      scope.setTag('response_status', context.response.status);
    }

    if (networkError) {
      Sentry.captureException(networkError, scope);
    }

    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        // Extend the default fingerprint for better operation/error grouping
        const fingerprint = ['{{ default }}', operation.operationName];

        for (const [key, value] of Object.entries(error.extensions)) {
          if (
            typeof value === 'string' &&
            (key === 'code' || key === 'field')
          ) {
            fingerprint.push(value);
          }
          if (isPrimitive(value)) {
            scope.setTag(`error_extension_${key}`, value);
          }
        }
        scope.setExtra('error', error);
        scope.setFingerprint(fingerprint);

        // NOTE: as of writing the upstream typing for `graphQLErrors` seems wrong
        // as its elements is plain objects and not instances of `GraphQLError`...
        // hence generating new a `GraphQLError` to make it more helpful to sentry
        // https://github.com/apollographql/apollo-client/issues/11168
        Sentry.captureException(
          new GraphQLError(error.message, {
            extensions: error.extensions,
            path: error.path,
          }),
          scope,
        );
      }
    }
  });

type CreateUserClientOptions = CreateSentryLinkOptions;

/** Creates apollo client that should be used by users for most requests. */
export function createUserClient({
  ignoreLoggingErrorToSentry,
}: CreateUserClientOptions = {}) {
  const httpLink = createHttpLink({
    uri: getBackendUrl('/graphql').href,
    headers: { [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue('web') },
  });
  const authLink = createUserAuthLink();
  const sentryLink = createSentryLink({ ignoreLoggingErrorToSentry });

  return new ApolloClient({
    link: from([sentryLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

type CreateOverviewClientOptions = CreateSentryLinkOptions;

/** Creates apollo client that should be used by the overview screen. */
export function createOverviewClient({
  ignoreLoggingErrorToSentry,
}: CreateOverviewClientOptions) {
  const httpLink = createHttpLink({
    uri: getBackendUrl('/graphql').href,
    headers: {
      [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue('overview'),
    },
  });
  const authLink = createOverviewAuthLink();
  const sentryLink = createSentryLink({ ignoreLoggingErrorToSentry });

  return new ApolloClient({
    link: from([sentryLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

// Creates an apollo client that can be used for mocking requets.
// Inspired by https://github.com/apollographql/apollo-client/blob/main/src/testing/core/mocking/mockClient.ts
// but composed our own to allow mocking errors in the result
export function createMockClient<TData>({
  responses,
  link,
}: {
  responses: MockedResponse<TData>[];
  link?: ApolloLink;
}) {
  return new ApolloClient({
    link: (link
      ? from([link, new MockLink(responses)])
      : new MockLink(responses)
    ).setOnError((error) => {
      throw error;
    }),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      mutate: { fetchPolicy: 'no-cache' },
      query: { fetchPolicy: 'no-cache' },
    },
  });
}
