import Box, { BoxProps } from '@mui/material/Box';

export type FormProps = BoxProps<'form'>;

export function Form({ children, sx, ...boxProps }: FormProps) {
  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        '.MuiButton-root:last-child': { alignSelf: 'baseline' },
        '.MuiAlert-root': { mb: 1 },
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

export default Form;
