export function AppleAppStoreBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="146"
      height="43"
      viewBox="0 0 146 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M140.323 43H5.09681C2.28704 43 0 40.7124 0 37.912V5.08045C0 2.279 2.28704 0 5.09681 0H140.323C143.132 0 145.5 2.279 145.5 5.08045V37.912C145.5 40.7124 143.132 43 140.323 43Z"
        fill="#A6A6A6"
      />
      <path
        d="M144.457 37.9131C144.457 40.1878 142.609 42.0304 140.325 42.0304H5.09682C2.81301 42.0304 0.959229 40.1878 0.959229 37.9131V5.07937C0.959229 2.80575 2.81301 0.956749 5.09682 0.956749H140.323C142.609 0.956749 144.456 2.80575 144.456 5.07937L144.457 37.9131Z"
        fill="black"
      />
      <path
        d="M32.4713 21.2678C32.44 17.8031 35.3155 16.1175 35.447 16.039C33.8185 13.6708 31.2944 13.3472 30.4073 13.3214C28.2874 13.0989 26.231 14.5867 25.151 14.5867C24.0495 14.5867 22.3865 13.3429 20.5942 13.3794C18.2877 13.4149 16.13 14.7468 14.9466 16.8151C12.5044 21.0324 14.3258 27.2297 16.6657 30.6386C17.8361 32.308 19.2038 34.1721 20.994 34.1065C22.7454 34.0345 23.3996 32.9928 25.5132 32.9928C27.6073 32.9928 28.2216 34.1065 30.0474 34.0646C31.927 34.0345 33.1104 32.3876 34.2399 30.7031C35.5925 28.7896 36.1357 26.9051 36.1573 26.8084C36.1131 26.7933 32.5069 25.4205 32.4713 21.2678Z"
        fill="white"
      />
      <path
        d="M29.0224 11.0789C29.9644 9.90397 30.6089 8.30545 30.43 6.68327C29.0666 6.74347 27.3616 7.6239 26.3797 8.77307C25.511 9.78572 24.735 11.4455 24.9355 13.0064C26.467 13.1204 28.0395 12.2356 29.0224 11.0789Z"
        fill="white"
      />
      <path
        d="M57.8174 33.8668H55.3698L54.029 29.6646H49.3687L48.0915 33.8668H45.7086L50.3258 19.5607H53.1776L57.8174 33.8668ZM53.6248 27.9016L52.4123 24.166C52.2841 23.7844 52.0437 22.8857 51.6891 21.471H51.646C51.5048 22.0794 51.2774 22.9781 50.9649 24.166L49.7739 27.9016H53.6248Z"
        fill="white"
      />
      <path
        d="M69.6913 28.5821C69.6913 30.3365 69.216 31.7233 68.2654 32.7413C67.4139 33.6475 66.3566 34.1001 65.0945 34.1001C63.7322 34.1001 62.7536 33.612 62.1576 32.6359H62.1145V38.0701H59.8167V26.947C59.8167 25.8441 59.7876 24.7121 59.7315 23.5511H61.7524L61.8806 25.1862H61.9237C62.69 23.9542 63.8529 23.3393 65.4136 23.3393C66.6336 23.3393 67.6521 23.8198 68.4669 24.782C69.2839 25.7452 69.6913 27.0115 69.6913 28.5821ZM67.3503 28.666C67.3503 27.6619 67.124 26.8342 66.6692 26.1827C66.1723 25.5033 65.5052 25.1636 64.6688 25.1636C64.1019 25.1636 63.5867 25.3528 63.1265 25.7258C62.6652 26.1021 62.3635 26.5934 62.2223 27.2018C62.1511 27.4856 62.1156 27.7178 62.1156 27.9006V29.6206C62.1156 30.3709 62.3462 31.0041 62.8075 31.5212C63.2688 32.0382 63.868 32.2962 64.6052 32.2962C65.4707 32.2962 66.1443 31.963 66.6261 31.2986C67.1089 30.6332 67.3503 29.756 67.3503 28.666Z"
        fill="white"
      />
      <path
        d="M81.5867 28.5821C81.5867 30.3365 81.1114 31.7233 80.1597 32.7413C79.3094 33.6475 78.2521 34.1001 76.99 34.1001C75.6277 34.1001 74.649 33.612 74.0541 32.6359H74.011V38.0701H71.7132V26.947C71.7132 25.8441 71.6841 24.7121 71.628 23.5511H73.6489L73.7771 25.1862H73.8202C74.5855 23.9542 75.7484 23.3393 77.3101 23.3393C78.5291 23.3393 79.5476 23.8198 80.3645 24.782C81.1782 25.7452 81.5867 27.0115 81.5867 28.5821ZM79.2458 28.666C79.2458 27.6619 79.0184 26.8342 78.5635 26.1827C78.0667 25.5033 77.4017 25.1636 76.5643 25.1636C75.9963 25.1636 75.4822 25.3528 75.0209 25.7258C74.5596 26.1021 74.2589 26.5934 74.1177 27.2018C74.0476 27.4856 74.011 27.7178 74.011 27.9006V29.6206C74.011 30.3709 74.2416 31.0041 74.7008 31.5212C75.1621 32.0372 75.7613 32.2962 76.5007 32.2962C77.3661 32.2962 78.0397 31.963 78.5215 31.2986C79.0044 30.6332 79.2458 29.756 79.2458 28.666Z"
        fill="white"
      />
      <path
        d="M94.8865 29.8549C94.8865 31.0718 94.4629 32.0619 93.6125 32.8262C92.6781 33.6615 91.3772 34.0786 89.7056 34.0786C88.1622 34.0786 86.9249 33.7819 85.9883 33.1874L86.5208 31.2771C87.5296 31.8856 88.6364 32.1909 89.8425 32.1909C90.7079 32.1909 91.3815 31.9952 91.8655 31.6061C92.3472 31.2169 92.5876 30.6945 92.5876 30.043C92.5876 29.4625 92.3893 28.9734 91.9916 28.5767C91.596 28.1801 90.9353 27.8113 90.0128 27.4706C87.5015 26.5364 86.247 25.1679 86.247 23.3684C86.247 22.1923 86.6867 21.228 87.5673 20.4777C88.4446 19.7262 89.6151 19.3511 91.0787 19.3511C92.3839 19.3511 93.4681 19.5779 94.3336 20.0305L93.7591 21.8988C92.9508 21.4602 92.0368 21.2409 91.014 21.2409C90.2057 21.2409 89.5741 21.4398 89.1214 21.8354C88.7388 22.1891 88.547 22.6202 88.547 23.1308C88.547 23.6962 88.7658 24.1638 89.2055 24.5315C89.5881 24.8712 90.2833 25.2389 91.2921 25.6355C92.5261 26.1311 93.4326 26.7105 94.0156 27.3749C94.5966 28.0371 94.8865 28.8659 94.8865 29.8549Z"
        fill="white"
      />
      <path
        d="M102.484 25.2711H99.951V30.2795C99.951 31.5534 100.397 32.1898 101.292 32.1898C101.702 32.1898 102.043 32.1543 102.312 32.0834L102.376 33.8238C101.923 33.9926 101.327 34.0775 100.589 34.0775C99.6815 34.0775 98.9723 33.8012 98.4604 33.2497C97.9506 32.6972 97.6941 31.7705 97.6941 30.4687V25.269H96.1852V23.549H97.6941V21.6602L99.951 20.9808V23.549H102.484V25.2711Z"
        fill="white"
      />
      <path
        d="M113.911 28.624C113.911 30.2097 113.457 31.5115 112.549 32.5295C111.597 33.5776 110.334 34.1001 108.76 34.1001C107.242 34.1001 106.034 33.598 105.133 32.594C104.232 31.5899 103.781 30.3225 103.781 28.7949C103.781 27.1964 104.245 25.8871 105.175 24.8691C106.103 23.85 107.355 23.3404 108.93 23.3404C110.447 23.3404 111.669 23.8424 112.59 24.8476C113.472 25.8226 113.911 27.0814 113.911 28.624ZM111.527 28.6982C111.527 27.7468 111.324 26.9309 110.911 26.2504C110.429 25.427 109.74 25.0163 108.848 25.0163C107.924 25.0163 107.223 25.4281 106.741 26.2504C106.328 26.932 106.124 27.7608 106.124 28.7412C106.124 29.6926 106.328 30.5085 106.741 31.1879C107.238 32.0113 107.932 32.422 108.828 32.422C109.705 32.422 110.394 32.0027 110.89 31.1664C111.314 30.473 111.527 29.6485 111.527 28.6982Z"
        fill="white"
      />
      <path
        d="M121.38 25.5667C121.153 25.5248 120.91 25.5033 120.656 25.5033C119.848 25.5033 119.223 25.8075 118.783 26.417C118.4 26.9545 118.209 27.6339 118.209 28.4542V33.8668H115.912L115.933 26.7997C115.933 25.6108 115.904 24.5283 115.847 23.5522H117.849L117.933 25.5259H117.996C118.239 24.8476 118.621 24.3014 119.145 23.8919C119.657 23.5231 120.21 23.3393 120.806 23.3393C121.018 23.3393 121.21 23.3544 121.38 23.3812V25.5667Z"
        fill="white"
      />
      <path
        d="M131.657 28.2209C131.657 28.6316 131.63 28.9777 131.573 29.2604H124.679C124.706 30.2795 125.039 31.0589 125.68 31.5964C126.261 32.0769 127.012 32.3177 127.934 32.3177C128.955 32.3177 129.886 32.1554 130.724 31.8297L131.084 33.4207C130.105 33.8464 128.95 34.0581 127.616 34.0581C126.013 34.0581 124.754 33.5873 123.838 32.6467C122.924 31.706 122.466 30.4429 122.466 28.8584C122.466 27.3028 122.891 26.0075 123.744 24.9744C124.636 23.8715 125.842 23.32 127.36 23.32C128.85 23.32 129.979 23.8715 130.745 24.9744C131.352 25.8505 131.657 26.9341 131.657 28.2209ZM129.466 27.6264C129.481 26.947 129.331 26.3601 129.02 25.8645C128.622 25.227 128.011 24.9088 127.189 24.9088C126.437 24.9088 125.826 25.2195 125.36 25.843C124.977 26.3386 124.75 26.933 124.679 27.6253L129.466 27.6264Z"
        fill="white"
      />
      <path
        d="M52.865 10.7597C52.865 12.0249 52.4845 12.9774 51.7247 13.617C51.0209 14.2072 50.0207 14.5028 48.7253 14.5028C48.0829 14.5028 47.5332 14.4749 47.073 14.419V7.50565C47.6733 7.4089 48.32 7.35945 49.0184 7.35945C50.2525 7.35945 51.1826 7.62712 51.8099 8.16247C52.5126 8.7677 52.865 9.63307 52.865 10.7597ZM51.6741 10.7908C51.6741 9.97062 51.4563 9.34175 51.0209 8.90315C50.5855 8.46562 49.9496 8.24632 49.1122 8.24632C48.7565 8.24632 48.4537 8.26997 48.2025 8.31942V13.5751C48.3416 13.5966 48.5959 13.6063 48.9656 13.6063C49.83 13.6063 50.4971 13.3665 50.967 12.8871C51.4369 12.4076 51.6741 11.7089 51.6741 10.7908Z"
        fill="white"
      />
      <path
        d="M59.1797 11.8648C59.1797 12.6441 58.9566 13.2827 58.5104 13.7836C58.0426 14.2986 57.4229 14.5555 56.6491 14.5555C55.9033 14.5555 55.3094 14.3093 54.8664 13.8148C54.4245 13.3214 54.2036 12.699 54.2036 11.9486C54.2036 11.1639 54.431 10.5199 54.888 10.0201C55.345 9.5202 55.9593 9.26972 56.7331 9.26972C57.479 9.26972 58.0782 9.5159 58.532 10.0093C58.9631 10.4888 59.1797 11.108 59.1797 11.8648ZM58.0082 11.9013C58.0082 11.4337 57.9068 11.0327 57.7053 10.6984C57.4682 10.2942 57.1308 10.0921 56.6922 10.0921C56.2384 10.0921 55.8936 10.2942 55.6564 10.6984C55.4538 11.0327 55.3536 11.4401 55.3536 11.9217C55.3536 12.3894 55.4549 12.7903 55.6564 13.1247C55.9011 13.5289 56.2417 13.731 56.6814 13.731C57.1125 13.731 57.4509 13.5256 57.6945 13.1139C57.9036 12.7731 58.0082 12.3689 58.0082 11.9013Z"
        fill="white"
      />
      <path
        d="M67.6467 9.37292L66.057 14.4405H65.0223L64.3638 12.2399C64.1968 11.6906 64.061 11.1445 63.9553 10.6027H63.9349C63.8368 11.1596 63.701 11.7046 63.5264 12.2399L62.8269 14.4405H61.7804L60.2855 9.37292H61.4463L62.0207 11.782C62.1598 12.3518 62.274 12.8946 62.3656 13.4085H62.3861C62.4702 12.9849 62.6092 12.4453 62.8053 11.7928L63.5264 9.374H64.4468L65.1377 11.7411C65.3047 12.3184 65.4405 12.8742 65.5451 13.4096H65.5763C65.6528 12.8882 65.7682 12.3324 65.9212 11.7411L66.5377 9.374H67.6467V9.37292Z"
        fill="white"
      />
      <path
        d="M73.5023 14.4405H72.3728V11.538C72.3728 10.6436 72.0322 10.1964 71.3489 10.1964C71.0137 10.1964 70.7432 10.3189 70.533 10.5651C70.325 10.8113 70.2194 11.1015 70.2194 11.4337V14.4394H69.0899V10.8209C69.0899 10.3759 69.0759 9.89322 69.0489 9.37077H70.0415L70.0944 10.163H70.1256C70.2571 9.91687 70.4533 9.7137 70.7108 9.55137C71.0169 9.36217 71.3597 9.2665 71.7347 9.2665C72.209 9.2665 72.6034 9.41915 72.9171 9.72552C73.3072 10.1007 73.5023 10.6608 73.5023 11.4047V14.4405Z"
        fill="white"
      />
      <path d="M76.6171 14.4405H75.4886V7.0477H76.6171V14.4405Z" fill="white" />
      <path
        d="M83.267 11.8648C83.267 12.6441 83.0439 13.2827 82.5977 13.7836C82.1299 14.2986 81.5091 14.5555 80.7363 14.5555C79.9894 14.5555 79.3956 14.3093 78.9537 13.8148C78.5118 13.3214 78.2909 12.699 78.2909 11.9486C78.2909 11.1639 78.5183 10.5199 78.9752 10.0201C79.4322 9.5202 80.0466 9.26972 80.8193 9.26972C81.5662 9.26972 82.1644 9.5159 82.6192 10.0093C83.0503 10.4888 83.267 11.108 83.267 11.8648ZM82.0943 11.9013C82.0943 11.4337 81.993 11.0327 81.7915 10.6984C81.5554 10.2942 81.217 10.0921 80.7794 10.0921C80.3246 10.0921 79.9797 10.2942 79.7437 10.6984C79.5411 11.0327 79.4408 11.4401 79.4408 11.9217C79.4408 12.3894 79.5422 12.7903 79.7437 13.1247C79.9884 13.5289 80.3289 13.731 80.7687 13.731C81.1998 13.731 81.5371 13.5256 81.7807 13.1139C81.9909 12.7731 82.0943 12.3689 82.0943 11.9013Z"
        fill="white"
      />
      <path
        d="M88.7334 14.4405H87.7193L87.6352 13.8567H87.6039C87.2569 14.3222 86.7622 14.5555 86.1198 14.5555C85.6402 14.5555 85.2522 14.4018 84.9601 14.0965C84.695 13.8191 84.5624 13.474 84.5624 13.0645C84.5624 12.4453 84.8211 11.9733 85.3417 11.6465C85.8612 11.3197 86.5919 11.1596 87.5328 11.1671V11.0725C87.5328 10.4049 87.1814 10.0717 86.4777 10.0717C85.9765 10.0717 85.5346 10.1974 85.1531 10.4468L84.9235 9.70725C85.3956 9.41592 85.9786 9.26972 86.6663 9.26972C87.9941 9.26972 88.6602 9.96847 88.6602 11.366V13.2322C88.6602 13.7385 88.6849 14.1416 88.7334 14.4405ZM87.5608 12.699V11.9174C86.3149 11.8959 85.692 12.2367 85.692 12.9387C85.692 13.2031 85.7631 13.4009 85.9086 13.5332C86.0541 13.6654 86.2395 13.731 86.4604 13.731C86.7083 13.731 86.94 13.6525 87.1513 13.4966C87.3636 13.3397 87.494 13.1408 87.5425 12.8968C87.5544 12.8419 87.5608 12.7753 87.5608 12.699Z"
        fill="white"
      />
      <path
        d="M95.1516 14.4405H94.1493L94.0965 13.6267H94.0652C93.7451 14.2459 93.1998 14.5555 92.4335 14.5555C91.8213 14.5555 91.3115 14.3158 90.9073 13.8363C90.5032 13.3569 90.3016 12.7344 90.3016 11.9701C90.3016 11.1499 90.5204 10.4855 90.9601 9.97815C91.3859 9.50515 91.9075 9.26865 92.5283 9.26865C93.2105 9.26865 93.688 9.49762 93.9596 9.95665H93.9811V7.0477H95.1117V13.0752C95.1117 13.5686 95.1247 14.0234 95.1516 14.4405ZM93.9811 12.3034V11.4584C93.9811 11.3122 93.9704 11.194 93.9499 11.1037C93.8863 10.8328 93.7494 10.6049 93.5414 10.421C93.3312 10.2372 93.078 10.1448 92.7859 10.1448C92.3645 10.1448 92.0347 10.3114 91.7922 10.6457C91.5518 10.98 91.43 11.4068 91.43 11.9282C91.43 12.4291 91.5454 12.8355 91.7771 13.1483C92.0217 13.4816 92.3515 13.6482 92.7643 13.6482C93.1351 13.6482 93.4315 13.5095 93.6567 13.2311C93.8744 12.9742 93.9811 12.6646 93.9811 12.3034Z"
        fill="white"
      />
      <path
        d="M104.812 11.8648C104.812 12.6441 104.589 13.2827 104.142 13.7836C103.675 14.2986 103.056 14.5555 102.281 14.5555C101.536 14.5555 100.943 14.3093 100.498 13.8148C100.057 13.3214 99.8356 12.699 99.8356 11.9486C99.8356 11.1639 100.063 10.5199 100.52 10.0201C100.977 9.5202 101.591 9.26972 102.366 9.26972C103.111 9.26972 103.711 9.5159 104.164 10.0093C104.595 10.4888 104.812 11.108 104.812 11.8648ZM103.641 11.9013C103.641 11.4337 103.54 11.0327 103.338 10.6984C103.1 10.2942 102.764 10.0921 102.324 10.0921C101.872 10.0921 101.527 10.2942 101.288 10.6984C101.086 11.0327 100.986 11.4401 100.986 11.9217C100.986 12.3894 101.087 12.7903 101.288 13.1247C101.533 13.5289 101.874 13.731 102.313 13.731C102.745 13.731 103.084 13.5256 103.328 13.1139C103.536 12.7731 103.641 12.3689 103.641 11.9013Z"
        fill="white"
      />
      <path
        d="M110.885 14.4405H109.757V11.538C109.757 10.6436 109.416 10.1964 108.732 10.1964C108.396 10.1964 108.126 10.3189 107.917 10.5651C107.708 10.8113 107.603 11.1015 107.603 11.4337V14.4394H106.473V10.8209C106.473 10.3759 106.46 9.89322 106.433 9.37077H107.424L107.477 10.163H107.508C107.641 9.91687 107.837 9.7137 108.094 9.55137C108.401 9.36217 108.742 9.2665 109.119 9.2665C109.592 9.2665 109.986 9.41915 110.3 9.72552C110.691 10.1007 110.885 10.6608 110.885 11.4047V14.4405Z"
        fill="white"
      />
      <path
        d="M118.487 10.2168H117.243V12.6785C117.243 13.3042 117.464 13.617 117.901 13.617C118.104 13.617 118.272 13.5998 118.405 13.5643L118.434 14.419C118.211 14.5028 117.918 14.5447 117.556 14.5447C117.11 14.5447 116.763 14.4093 116.512 14.1384C116.26 13.8675 116.135 13.4117 116.135 12.7721V10.2168H115.392V9.37292H116.135V8.44412L117.242 8.11087V9.37185H118.485L118.487 10.2168Z"
        fill="white"
      />
      <path
        d="M124.466 14.4405H123.336V11.5595C123.336 10.6511 122.995 10.1964 122.313 10.1964C121.789 10.1964 121.431 10.4597 121.235 10.9865C121.202 11.0972 121.182 11.2327 121.182 11.3918V14.4394H120.054V7.0477H121.182V10.1018H121.204C121.559 9.546 122.069 9.26865 122.73 9.26865C123.198 9.26865 123.584 9.4213 123.892 9.72767C124.274 10.1093 124.466 10.6769 124.466 11.4272V14.4405Z"
        fill="white"
      />
      <path
        d="M130.634 11.667C130.634 11.8691 130.619 12.0389 130.592 12.1776H127.205C127.22 12.6785 127.381 13.0602 127.695 13.3246C127.982 13.5611 128.351 13.6794 128.804 13.6794C129.305 13.6794 129.762 13.5998 130.174 13.4396L130.351 14.2222C129.869 14.4308 129.302 14.5351 128.646 14.5351C127.859 14.5351 127.239 14.3039 126.791 13.8417C126.34 13.3794 126.117 12.7592 126.117 11.9809C126.117 11.2165 126.325 10.5801 126.745 10.0727C127.182 9.53095 127.774 9.26005 128.521 9.26005C129.251 9.26005 129.806 9.53095 130.182 10.0727C130.484 10.5027 130.634 11.0349 130.634 11.667ZM129.556 11.3756C129.565 11.0413 129.491 10.7532 129.338 10.5103C129.141 10.1974 128.843 10.0405 128.439 10.0405C128.07 10.0405 127.769 10.1931 127.54 10.4995C127.352 10.7435 127.241 11.0349 127.205 11.3756H129.556Z"
        fill="white"
      />
    </svg>
  );
}

export default AppleAppStoreBadge;
