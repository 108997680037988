import { gql, useMutation } from '@apollo/client';
import {
  OrganizationCard,
  OrganizationCardActions,
  OrganizationCardDescription,
  OrganizationCardTitle,
} from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import {
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables,
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables,
  InvitationFieldsFragment,
  InvitationStatus,
} from '../../../../../generated/graphql';
import { Q_PROFILE } from '../../../../../hooks/useProfileQuery';
import locale from '../../../../../locale';

const LL = locale.pages.profile.organizationList.organizationCard;

const M_ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($id: ID!) {
    acceptInvitation(id: $id) {
      id
    }
  }
`;

const M_DECLINE_INVITATION = gql`
  mutation DeclineInvitation($id: ID!) {
    declineInvitation(id: $id) {
      id
    }
  }
`;

type InvitationOrganizationCardActionsProps = {
  invitationId: string;
  disabled: boolean;
};

function InvitationOrganizationCardActions({
  invitationId,
  disabled,
}: InvitationOrganizationCardActionsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [acceptInvitation, { loading: acceptLoading }] = useMutation<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >(M_ACCEPT_INVITATION, {
    refetchQueries: [Q_PROFILE],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(LL.alerts.AcceptInvitationMutation.success, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(LL.alerts.AcceptInvitationMutation.failure, {
        variant: 'error',
      });
    },
  });

  const [declineInvitation, { loading: declineLoading }] = useMutation<
    DeclineInvitationMutation,
    DeclineInvitationMutationVariables
  >(M_DECLINE_INVITATION, {
    refetchQueries: [Q_PROFILE],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(LL.alerts.DeclineInvitationMutation.success, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(LL.alerts.DeclineInvitationMutation.failure, {
        variant: 'error',
      });
    },
  });

  return (
    <OrganizationCardActions>
      <LoadingButton
        disabled={!disabled || declineLoading}
        loading={acceptLoading}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => acceptInvitation({ variables: { id: invitationId } })}
      >
        {LL.joinButton}
      </LoadingButton>
      <LoadingButton
        disabled={!disabled || acceptLoading}
        loading={declineLoading}
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => declineInvitation({ variables: { id: invitationId } })}
      >
        {LL.declineButton}
      </LoadingButton>
    </OrganizationCardActions>
  );
}

type InvitationOrganizationCardProps = {
  invitation: InvitationFieldsFragment;
  verified: boolean;
};

export function InvitationOrganizationCard({
  invitation,
  verified,
}: InvitationOrganizationCardProps) {
  const expired = invitation.status === InvitationStatus.Expired;

  return (
    <OrganizationCard
      key={invitation.id}
      logoUrl={invitation.organization?.logoUrl ?? undefined}
      logoDimmed={!verified}
    >
      <OrganizationCardTitle>
        {invitation.organization?.name}
      </OrganizationCardTitle>
      {expired && (
        <OrganizationCardDescription color="warning.main">
          {LL.expired}
        </OrganizationCardDescription>
      )}
      {!expired && (
        <InvitationOrganizationCardActions
          invitationId={invitation.id}
          disabled={verified}
        />
      )}
    </OrganizationCard>
  );
}
