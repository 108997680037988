export const FaqIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.7904 0a.48.48 0 0 0-.39.48V21.6c0 .2644.2157.48.48.48h1.44v1.44c0 .2644.2157.48.48.48h16.3201a.481.481 0 0 0 .48-.48V2.4a.481.481 0 0 0-.48-.48h-1.44V.48a.481.481 0 0 0-.48-.48H2.7905Zm.57.96h15.3601v20.16h-15.36V.96Zm16.3201 1.92h.96v20.16h-15.36v-.96h13.92a.481.481 0 0 0 .48-.48V2.88Zm-8.55 2.16c-1.7757 0-2.97 1.0987-3.21 2.73 0 .0956.0543.195.15.195l1.095.195c.0956 0 .195-.0544.195-.15.1912-1.0556.81-1.62 1.77-1.62 1.0087 0 1.68.615 1.68 1.575 0 .6244-.1857 1.0144-.81 1.83l-1.155 1.575c-.3844.48-.525.8737-.525 1.545v.72c0 .0956.1012.195.15.195h1.14c.1443 0 .195-.0994.195-.195v-.525c0-.5756.0993-.825.435-1.305l1.155-1.575c.5756-.8156.855-1.4494.855-2.265 0-1.7287-1.2488-2.925-3.12-2.925Zm-.66 10.08c-.1444 0-.195.0994-.195.195V16.8c0 .1444.0993.195.195.195h1.29c.1443 0 .195-.0994.195-.195v-1.485c0-.1444-.0994-.195-.195-.195h-1.29Z"
    />
  </svg>
);
