import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import type { ProfileQuery } from '../generated/graphql';

export const Q_PROFILE = gql`
  fragment MembershipFields on Membership {
    id
    role
    userId
    orgId
    status
    organization {
      id
      name
      logoUrl
      domain
    }
  }

  fragment InvitationFields on Invitation {
    id
    status
    organization {
      id
      name
      logoUrl
      domain
    }
    membership {
      id
      role
    }
  }

  fragment ProfileFields on User {
    name
    email
    verified
    superAdmin
    memberships {
      ...MembershipFields
    }
    invitations(filter: { status: [PENDING, EXPIRED] }) {
      ...InvitationFields
    }
  }

  query Profile {
    profile {
      ...ProfileFields
    }
  }
`;

export function useProfileQuery(options?: QueryHookOptions<ProfileQuery>) {
  const { data, ...rest } = useQuery<ProfileQuery>(Q_PROFILE, options);
  const profile = data?.profile;
  return { profile, ...rest };
}
