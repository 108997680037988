import { useEffect } from 'react';
import { AuthError, useAuth } from './AuthContext';

type RequireAuthProps = {
  children: React.ReactNode;
  /** Fallback element to render while redirecting to sign loads. */
  fallback: React.ReactNode;
  /** Error element to render if redirecting to sign in fails. */
  errorElement: ({ error }: { error: AuthError }) => React.ReactNode;
  /**
   * Return user to the provided URL after successful sign in (if redirected to sign in).
   * @default () => new URL(window.location.href)
   */
  returnTo?: URL | (() => URL);
  /** Prefill the provided email in the sign in or registration form (if redirected to sign in). */
  loginHint?: string;
};

/**
 * Requires user to be authenticated to render `children`. If not authenticated
 * the component automatically redirects to sign in.
 */
export function RequireAuth({
  children,
  fallback,
  errorElement,
  returnTo = () => new URL(window.location.href),
  loginHint,
}: RequireAuthProps) {
  const { authenticated, loading, error, signInRedirect } = useAuth();

  const isSignInRedirectError = error && error.type === 'sign-in-redirect';

  useEffect(() => {
    if (!authenticated && !isSignInRedirectError && !loading) {
      signInRedirect({
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        loginHint,
        errorPolicy: 'catch',
      });
    }
  }, [
    authenticated,
    signInRedirect,
    returnTo,
    loginHint,
    isSignInRedirectError,
    loading,
  ]);

  if (isSignInRedirectError) {
    return errorElement({ error });
  }

  if (loading || (!authenticated && !error)) {
    return fallback;
  }

  return children;
}
